module.exports={
    "PLATFORM_ID": "geotrek-rando-GTE",
    "API_URL": "https://rando.pnr-idf.fr",
    "BACKOFFICE_URL": "https://geotrek-admin.pnr-idf.fr",
    "ENABLE_HTML_MODE": true,
    "SHOW_FOOTER": false,
    "LOGO_FILE": "pnridf-logos.svg",
    "APPROVED_BIG": "/custom/public/images/pnridf-logos.png",
    "APPROVED_SMALL": "/custom/public/images/pnridf-logos-small.png",
    "APPROVED_LABEL": "Randonnées des Parcs naturels d'Île-de-France",
    "HOME_TEMPLATE_FILE": {
        "fr": "custom-home-fr.html"
    },
    "HEADER_TEMPLATE_FILE": "custom-header.html",
    "FOOTER_TEMPLATE_FILE": "custom-footer.html",
    "DEFAULT_LANGUAGE": "fr",
    "ENABLED_LANGUAGES": ["fr", "en"],
    "DEFAULT_ACTIVE_CATEGORIES": ["T1", "T3", "T4", "T5"],
    "RANDOMIZE_RESULTS": true,
    "PERMANENT_TILELAYERS":[
		{
            "LAYER_URL": "//wxs.ign.fr/by039xlju3g3knftxybfi85x/wmts?LAYER=GEOGRAPHICALGRIDSYSTEMS.MAPS.SCAN25TOUR&EXCEPTIONS=text/xml&FORMAT=image/jpeg&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}",
            "OPTIONS": {
                "attribution": "(c) IGN Geoportail 2022",
                "minZoom": 4,
                "maxZoom": 16
            }
        }//,
	//{
        //    "LAYER_URL": "https://wxs.ign.fr/ai18lzefpevrjnsehj3t1xrz/geoportail/wmts?LAYER=GEOGRAPHICALGRIDSYSTEMS.MAPS.SCAN-EXPRESS.STANDARD&EXCEPTIONS=text/xml&FORMAT=image/jpeg&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}",
        //    "OPTIONS": {
        //        "attribution": "(c) IGN Geoportail 2020",
        //        "minZoom": 17,
        //        "maxZoom": 17
        //    }
        //}

    ],
    "ORTHOPHOTO_TILELAYERS_NAME": "Photo",
    "ORTHOPHOTO_TILELAYERS": {
        "LAYER_URL": "https://wxs.ign.fr/essentiels/geoportail/wmts?LAYER=ORTHOIMAGERY.ORTHOPHOTOS&EXCEPTIONS=text/xml&FORMAT=image/jpeg&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}",
        "OPTIONS": {
            "id": "satellite",
            "attribution": "(c) IGN Geoportail 2020"
        }
    },
    "OPTIONAL_TILELAYERS": [
        {
            "LAYER_URL": "/custom/limites-parcs.geojson",
            "OPTIONS": {
                "force": true
            }
        }
    ],
    "TREKS_TO_GEOJSON_ZOOM_LEVEL": 13,
    "UPDATE_MAP_ON_FILTER": true,
    "NEAR_ELEMENTS_CATEGORIES": false,
    "LEAFLET_CONF": {
        "CENTER_LATITUDE": 48.833,
        "CENTER_LONGITUDE": 2.301,
        "DEFAULT_ZOOM": 8,
        "DEFAULT_MIN_ZOOM": 6,
        "DEFAULT_MAX_ZOOM": 17
    },
    "ACTIVE_MINIMAP": false,
	"FACEBOOK_APP_ID": "1214170892055373",
	"GOOGLE_ANALYTICS_ID":"UA-136900373-1",
    "FILTERS_DEFAULT_OPEN": true,
    "FILTER_BY_VIEWPORT_DEFAULT": true,
    "DISPLAY_ASIDES_COUNTERS": true,
    "POI_EXPANDED": true,
    "SHOW_ARROWS_ON_ROUTE": true,
    "ENABLE_STRUCTURE_FILTERING": false,
    "DURATION_FILTER": [
        { "id": 0, "label": "< 1/2 J"},
        { "id": 4, "label": "1/2 J"},
        { "id": 10, "label": "1 J"},
        { "id": 999999, "label": "> 1 J"}
    ],
    "ASCENT_FILTER":  [],
    "LENGTH_FILTER":  [
        { "id": 0, "label": "< 10km"},
        { "id": 10000, "label": "10km"},
        { "id": 30000, "label": "30km"},
        { "id": 50000, "label": "50km"},
        { "id": 9999999, "label": "> 50km"}
    ]
}
